import React from "react";
import { Link } from "react-router-dom";
import DefaultLayout from "../components/layouts/DefaultLayout";

function Products() {
  return (
    <DefaultLayout>
      <h1>Products</h1>
      <p>Test Product</p>
      <Link to={"/"}>Home</Link>
      <Link to={"/products"}>Products</Link>
    </DefaultLayout>
  );
}

export default Products;
