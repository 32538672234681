import React, { FC } from "react";
import Container from "../containers/Container";
import ImageUserInfo from "../../assets/image/home/user-info.png";
import ImageIssueSubmission from "../../assets/image/home/issue-submission.png";

type SectionAdvanceServiceProps = {
  id: string;
};

const SectionAdvanceService: FC<SectionAdvanceServiceProps> = ({ id }) => {
  return (
    <section id={id} className="pt-20 sm:pt-25 lg:pt-30">
      <Container>
        <h2 className="md:bg-topic-color md:text-white text-center text-24-semibold sm:text-32-semibold lg:text-36-semibold mb-8 lg:mb-12">
          Extra care service
        </h2>
        <div className="flex flex-col md:flex-row gap-y-5 gap-x-5">
          <div className="flex flex-col gap-y-4 sm:flex-row items-center gap-x-10">
            <img src={ImageUserInfo} alt="" className="w-[100px]" />
            <div className="flex flex-col gap-y-2">
              <h3 className="text-left text-24-semibold">
                User info verification
              </h3>
              <p className="text-left text-16-regular text-justify">
                This function will get your deposit/withdrawal parts{" "}
                <span className="text-primary">
                  secured from all scam cases
                </span>{" "}
                because all the players will be allowed to deposit/withdraw on
                the system only after their{" "}
                <span className="text-primary">
                  {" "}
                  bank account info are already verified
                </span>
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-4 sm:flex-row items-center gap-x-10">
            <img src={ImageIssueSubmission} alt="" className="w-[100px]" />
            <div className="flex flex-col gap-y-2">
              <h3 className="text-left text-24-semibold">
                Issue submission menu
              </h3>
              <p className="text-left text-16-regular text-justify">
                This free menu is created to provide the best 24/7 customer
                service for all our 3rd party players in order to{" "}
                <span className="text-primary">
                  ensure that all their deposit/withdrawal issues can be
                  resolved as fast as possible
                </span>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionAdvanceService;
