import React, { FC } from "react";
import Container from "../containers/Container";
import GridContainer from "../containers/GridContainer";
import {
  FaLine,
  FaMap,
  FaSkype,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaHeadphonesAlt,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
// import ImageContactUs from "../../assets/image/home/contact-us.jpeg";
import ImageTh from "../../assets/image/aboutUs/th.svg";
import ImageIcon from "../../assets/image/home/icon-click.webp";
import ImageQRCode from "../../assets/image/home/qr-code.png";

type SectionContactUsProps = {
  id: string;
};

const SectionContactUs: FC<SectionContactUsProps> = ({ id }) => {
  const country = [
    {
      image: ImageTh,
      title: "Thailand",
      paragraph: `3/1, Moo.12, Sub-district robwieng, District Mueang, Chiangrai 57000`,
    },
  ];

  return (
    <section id={id} className="pt-24 pb-20 sm:pb-25 lg:pb-30">
      <Container>
        <h2 className="md:bg-topic-color md:text-white text-center text-24-semibold sm:text-32-semibold lg:text-36-semibold lg:mb-2">
          Contact us
        </h2>
        <GridContainer className="gap-y-0">
          <div className="relative col-start-1 col-end-7 gap-y-4 flex flex-col justify-start mx-auto md:mt-10">
            <img
              src={ImageIcon}
              alt=""
              className="absolute w-20 h-20 text-primary -right-[40px] top-[90px] md:right-0 md:top-20"
            />
            <div className="flex justify-start items-center gap-x-4">
              <a href="mailto:support@qr-pay.club">
                <MdEmail className="text-primary text-48-regular" />
              </a>
              <div className="text-left">
                <p className="text-16-regular sm:text-24-regular">
                  support@qr-pay.club
                </p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-x-4">
              <a href="https://lin.ee/v0mz0R3">
                <FaLine className="text-primary text-48-regular" />
              </a>
              <div className="text-left">
                <p className="text-16-regular sm:text-24-regular">@526omvyv</p>
              </div>
            </div>
            <div className="flex justify-start items-center gap-x-4">
              <a href="skype:qr-pay.club?chat">
                <FaSkype className="text-primary text-48-regular" />
              </a>
              <div className="text-left">
                <p className="text-16-regular sm:text-24-regular">
                  qr-pay.club
                </p>
              </div>
            </div>

            <img src={ImageQRCode} alt="" className="hidden md:block w-[150px] h-[300px] mx-auto"/>
          </div>
          <div className="col-start-1 col-end-7 md:col-start-7 md:col-end-13 gap-y-4 flex flex-col mx-auto">
            {country.map(({ image, title }, index) => {
              return (
                <div className="col-span-6 h-[180]" key={index}>
                  <img
                    src={image}
                    alt=""
                    className="relative left-6 top-8 flex justify-start"
                  />
                  <div className="bg-primary rounded-lg py-10 px-2">
                    <h6 className="text-white text-24-semibold mb-4">
                      {title}
                    </h6>
                    <p className="text-white text-18-regular">
                      3/1, Moo.12, Sub-district robwieng, District Mueang,{" "}
                      <br className="hidden md:block" />
                      Chiangrai 57000
                    </p>
                  </div>
                </div>
              );
            })}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3751.4602823194723!2d99.84109311539166!3d19.904999430561684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30d7065afddd31cd%3A0xae35509f80df9b60!2zMyDguJXguLPguJrguKUg4Lij4Lit4Lia4LmA4Lin4Li14Lii4LiHIOC4reC4s-C5gOC4oOC4reC5gOC4oeC4t-C4reC4h-C5gOC4iuC4teC4ouC4h-C4o-C4suC4oiDguYDguIrguLXguKLguIfguKPguLLguKIgNTcwMDA!5e0!3m2!1sth!2sth!4v1676305759550!5m2!1sth!2sth"
              // width="600"
              // height="450"
              title="map"
              loading="lazy"
              className="col-span-6 w-full h-[300px]"
            />
          </div>
          {/* <div className="col-span-6 lg:col-span-4 flex gap-x-4">
            <FaHeadphonesAlt className="text-primary text-48-regular" />
            <div className="text-left">
              <h3 className="text-18-semibold lg:text-24-semibold mb-4">
                Contact us
              </h3>
              <div className="flex flex-col gap-y-4">
                <Link
                  to={{ pathname: "https://lin.ee/v0mz0R3" }}
                  target="_blank"
                  className="no-underline text-[#221122] hover:text-sky-700"
                >
                  <div className="flex items-center gap-x-2">
                    <FaLine className="text-18-regular text-green-500" />
                    <p>https://lin.ee/v0mz0R3</p>
                  </div>
                </Link>
                <div className="flex items-center gap-x-2">
                  <FaSkype className="text-18-regular text-blue-400" />
                  <p>qr-pay.club</p>
                </div>
                <Link
                  to={{
                    pathname:
                      "https://www.google.com/maps/search/3%2F1,+Moo.12,+Sub-district+robwieng,+District+Mueang,+Chiangrai+57000/@19.9112982,99.8354666,15z/data=!3m1!4b1",
                  }}
                  target="_blank"
                  className="no-underline text-[#221122] hover:text-sky-700"
                >
                  <div className="flex items-center gap-x-2">
                    <FaMap className="text-18-regular text-primary" />
                    <p>Company location</p>
                  </div>
                </Link>
              </div>
            </div>
          </div> */}
        </GridContainer>
      </Container>
    </section>
  );
};

export default SectionContactUs;
