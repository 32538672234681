/* eslint-disable no-restricted-globals */
import React, { forwardRef, ReactNode, useEffect, useRef } from "react";
// import Footer from "./Footer";
import Header from "./Header";
// import Skype from "../../assets/image/skype.png";
import Logo from "../../assets/image/common/QR-PAY_LOGO.png";
import { FaLine, FaSkype } from "react-icons/fa";

type DefaultLayoutProps = {
  className?: string;
  children: ReactNode;
};

const DefaultLayout = forwardRef<HTMLDivElement, DefaultLayoutProps>(
  ({ className = "", children }, ref) => {
    const buttonToTopRef = useRef<HTMLButtonElement>(null);

    const handleShowToTop = () => {
      const buttonEl = buttonToTopRef.current as HTMLButtonElement;
      if (buttonEl) {
        if (window.scrollY >= 0) {
          buttonEl.classList.remove("hidden");
        } else {
          buttonEl.classList.add("hidden");
        }
      }
    };

    // const handleClickToTop = () => {
    //   window.scrollTo({
    //     top: 0,
    //   });
    // };

    useEffect(() => {
      addEventListener("scroll", handleShowToTop);
      return () => removeEventListener("scroll", handleShowToTop);
    }, []);
    return (
      <>
        <Header />
        <main>{children}</main>
        {/* <Footer /> */}
        <div
          className="flex flex-col md:gap-y-4 justify-start bg-white rounded-lg hidden md:block fixed md:w-[200px] py-4 px-4 md:right-6 lg:right-10 md:bottom-6 lg:bottom-7"
          style={{
            boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.4)",
          }}
        >
          <div className="flex justify-start mb-4">
            <img src={Logo} alt="" className="md:w-[80px] md:h-5 " />
          </div>
          <a href="skype:qr-pay.club?chat" className="no-underline text-primary">
            <div className="flex items-center rounded-lg bg-grey-bg p-1 mb-2">
              <FaSkype className="w-8 h-8 mr-2 text-blue-400" />
              <p className="text-16-regular">Chat with us</p>
            </div>
          </a>
          <a href="https://lin.ee/v0mz0R3" className="no-underline text-primary">
            <div className="flex items-center rounded-lg bg-grey-bg p-1">
              <FaLine className="w-8 h-8 mr-2 text-green-400" />
              <p>Chat with us</p>
            </div>
          </a>
          {/* <img src={Skype} alt="Skype" className="w-16 block hidden md:block" /> */}
          {/* <div
            className="md:hidden flex bg-grey-bg rounded-lg m-2 p-2 h-10"
            style={{
              boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.4)",
            }}
          >
            <FaSkype className="text-24-regular text-primary" />
          </div> */}
        </div>
        <div className="md:hidden fixed w-full no-underline right-0 bottom-4">
          <div
            className="md:hidden flex items-center gap-x-4 bg-grey-bg rounded-lg m-2 py-2 px-3 h-15"
            style={{
              boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.4)",
            }}
          >
            <img
              src={Logo}
              alt=""
              className="w-[80px] sm:w-[160px] h-5 sm:h-10"
            />
            <div className="flex flex-col w-full">
              <h4 className="text-left text-16-semibold text-black">
                แชทกับ QR-Pay
              </h4>
              <p className="text-left">ให้เราช่วยอะไรได้บ้าง</p>
            </div>
            <a href="skype:qr-pay.club?chat">
              <FaSkype className="w-7 h-7 sm:w-[60px] sm:h-[60px] sm:text-24-regular text-primary" />
            </a>
            <a href="https://lin.ee/v0mz0R3">
              <FaLine className="w-7 h-7 sm:w-[60px] sm:h-[60px] sm:text-24-regular text-primary mr-auto" />
            </a>
          </div>
        </div>
      </>
    );
  }
);
DefaultLayout.displayName = "default-layout";

export default DefaultLayout;
