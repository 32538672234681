import React, { ReactNode, FC } from "react";

type ContainerProps = {
  className?: string;
  children: ReactNode;
};
const Container: FC<ContainerProps> = ({ className = "", children }) => {
  return (
    <div
      className={`${className} mx-auto px-4 sm:px-6 xl:w-[1200px]`}
    >
      {children}
    </div>
  );
};

export default Container;
