import React, { FC, ReactNode } from 'react'

type GridContainerProps = {
  className?: string
  children: ReactNode
}
const gridClasses = 'grid grid-cols-6 sm:grid-cols-12 gap-y-8 sm:gap-x-6 lg:gap-x-12'
const GridContainer: FC<GridContainerProps> = ({
  className = '',
  children
}) => {
  return (
    <div className={`${className} ${gridClasses}`}>
      {children}
    </div>
  )
}

export default GridContainer
