import { Button, Carousel } from "antd";
import React from "react";
import Container from "../containers/Container";
import ImageBanner from "../../assets/image/home/banner.png";
import { Link, useNavigate } from "react-router-dom";
import ImageBannerMobile1 from "../../assets/image/home/banner-mobile-1.png";
import ImageBannerMobile2 from "../../assets/image/home/banner-mobile-2.png";
import ImageBannerMobile3 from "../../assets/image/home/banner-mobile-3.png";
import ImageBannerDesktop1 from "../../assets/image/home/banner-desktop-1.png";
import ImageBannerDesktop2 from "../../assets/image/home/banner-desktop-2.png";
import ImageBannerDesktop3 from "../../assets/image/home/banner-desktop-3.png";
const SectionBanner = () => {
  const navigate = useNavigate();

  return (
    <section>
      <Container>
        {/* <div className="flex justify-center items-center w-auto lg:w-1/2">
          <img src={ImageBanner} alt="" width={375} />
        </div>
        <div className="flex flex-col sm:mx-20 lg:mx-0 lg:w-1/2">
          <h1 className="mt-8 text-center lg:text-left text-32-semibold lg:text-64-semibold">
            Payment tech to grow your business
          </h1>
          <p className="sm:mt-8 lg:mt-12 hidden sm:block mb-12 text-left sm:text-center lg:text-left text-16-regular lg:text-24-regular text-grey font-pop">
            Seamless software and APIs that enable businesses to accept payments
            and financial institutions to innovate at scale
          </p>
          <div className="flex justify-center w-full lg:justify-start mt-10 sm:mt-0 ">
            <a href="#contact-us">
              <Button className="text-14-regular" type="primary">
                Contact us
              </Button>
            </a>
          </div>
        </div> */}
        <Carousel className="" fade autoplay dots arrows>
          <div className="flex mx-auto items-center justify-center">
            <a href="#contact-us" className="w-full">
              <img
                className="block md:hidden w-full"
                src={ImageBannerMobile1}
                alt=""
              />
              <img
                className="hidden md:block w-full"
                src={ImageBannerDesktop1}
                alt=""
              />
            </a>
          </div>
          <div className="flex mx-auto items-center justify-center">
            <a href="#contact-us" className="w-full">
              <img
                className="block md:hidden w-full"
                src={ImageBannerMobile2}
                alt=""
              />
              <img
                className="hidden md:block w-full"
                src={ImageBannerDesktop2}
                alt=""
              />
            </a>
          </div>
          <div className="flex mx-auto items-center justify-center">
            <a href="#contact-us" className="w-full">
              <img
                className="block md:hidden w-full"
                src={ImageBannerMobile3}
                alt=""
              />
              <img
                className="hidden md:block w-full"
                src={ImageBannerDesktop3}
                alt=""
              />
            </a>
          </div>
        </Carousel>
      </Container>
    </section>
  );
};

export default SectionBanner;
