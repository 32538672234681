import { Carousel } from "antd";
import Container from "../containers/Container";
import PaymentGateway1 from "../../assets/image/home/payment-gateway-1.jpeg";
import PaymentGateway2 from "../../assets/image/home/payment-gateway-2.jpeg";
import PaymentGateway3 from "../../assets/image/home/payment-gateway-3.jpeg";

const SectionReason = () => {
  return (
    <section className="mt-4 md:mt-6 pb-20 sm:pb-25 lg:pb-30">
      <Container>
        <h2 className="md:bg-topic-color md:text-white text-center text-24-semibold sm:text-32-semibold lg:text-36-semibold /mb-8 lg:mb-12">
          About us
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-6">
          <div className="row-start-2 row-end-3 col-start-1 col-end-6 md:row-start-1 md:row-end-2 md:col-end-3">
            <p className="mb-6 text-center md:text-left indent-6 md:indent-0 leading-[24px]">
              QR-Pay is one of the best Payment Gateway Services which aims to
              be the best online payment leader in the world. We strive to see
              online businesses succeed with a steady growth.
            </p>
            <p className="mb-6 text-center md:text-left indent-6 md:indent-0 leading-[24px]">
              With QR-Pay, your business is not only secured but it’s simple,
              convenient, effective and hassle-free. It uses special encryption
              and verification technology to ensure transactions is securely
              transmitted.
            </p>
            <p className="mb-6 text-center md:text-left indent-6 md:indent-0 leading-[24px]">
              We support trustworthy, multiple payments between merchant and
              customer with just one click through a patented QR Code.
            </p>
            <p className="mb-6 text-center md:text-left indent-6 md:indent-0 leading-[24px]">
              QR-Pay protects you and can help lessen the frequency and severity
              of fraud cases within e-commerce business.
            </p>
            <p className="text-center md:text-left indent-6 md:indent-0">
              You can be at ease with QR-Pay
            </p>
          </div>
          {/* <div className="row-start-1 row-end-2 col-start-1 col-end-3  md:col-start-2 md:col-end-3">
            <Carousel
              className="w-full mx-auto"
              fade
              autoplay
              dots
            >
              <div className="overflow-hidden h-full mx-0">
                <img
                  className="block h-[400px] sm:h-[450px] md:h-[580px]"
                  src={PaymentGateway1}
                  alt=""
                />
              </div>
              <div className="overflow-hidden h-full mx-0">
                <img
                  className="block h-[400px] sm:h-[450px] md:h-[580px]"
                  src={PaymentGateway2}
                  alt=""
                />
              </div>
              <div className="overflow-hidden h-full mx-0">
                <img
                  className="block h-[400px] sm:h-[450px] md:h-[580px]"
                  src={PaymentGateway3}
                  alt=""
                />
              </div>
            </Carousel>
          </div> */}
        </div>
      </Container>
    </section>
  );
};

export default SectionReason;
