import React from "react";
import SectionBanner from "../components/home/SectionBanner";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SectionReason from "../components/home/SectionReason";
import SectionWhyQRPay from "../components/home/SectionWhyQRPay";
import SectionAdvanceService from "../components/home/SectionAdvanceService";
// import SectionAboutUs from "../components/about-us/SectionAboutUs";
// import SectionOurLocation from "../components/about-us/SectionOurLocation";
import SectionContactUs from "../components/contact-us/SectionContactUs";

const Home = () => {
  return (
    <DefaultLayout>
      <SectionBanner />
      <SectionReason />
      <SectionWhyQRPay />
      <SectionAdvanceService id="advance-service" />
      {/* <SectionAboutUs id="about-us" /> */}
      <SectionContactUs id="contact-us" />
      {/* <SectionOurLocation id="our-location" /> */}
    </DefaultLayout>
  );
};

export default Home;
