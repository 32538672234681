/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef } from "react";
import SectionContactUs from "../components/contact-us/SectionContactUs";
import DefaultLayout from "../components/layouts/DefaultLayout";

const ContactUs = () => {
  return (
    <DefaultLayout>
      {/* <SectionContactUs id/> */}
    </DefaultLayout>
  );
};

export default ContactUs;
