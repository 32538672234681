import React from "react";
import Container from "../containers/Container";
import ImageSecurePayment from "../../assets/image/home/safe.svg";
import ImageSupportService from "../../assets/image/home/support.svg";
import ImageReasonablePrice from "../../assets/image/home/price.svg";
import ImageProvide from "../../assets/image/home/partner.svg";
import GridContainer from "../containers/GridContainer";

const SectionWhyQRPay = () => {
  const cards = [
    {
      image: ImageSecurePayment,
      title: "Safe & Fast system",
    },
    {
      image: ImageSupportService,
      title: "Professional customer support",
    },
    {
      image: ImageReasonablePrice,
      title: "Reasonable price",
    },
    {
      image: ImageProvide,
      title: "Creditable provider",
    },
  ];

  return (
    <section>
      <Container>
        <h2 className="md:bg-topic-color md:text-white text-center text-24-semibold sm:text-32-semibold lg:text-36-semibold mb-8 lg:mb-12">
          Why us ?
        </h2>
        <GridContainer>
          {cards.map(({ image, title }, index) => {
            return (
              <div className="col-span-6 md:col-span-3 mt-6" key={index}>
                <img
                  src={image}
                  alt=""
                  className="w-auto h-[200px] md:h-[180px]"
                />
                <h3 className="mt-4 md:mt-6 md:mb-4 text-18-medium">{title}</h3>
              </div>
            );
          })}
        </GridContainer>
      </Container>
    </section>
  );
};

export default SectionWhyQRPay;
