import { Button, Image, Popover } from "antd";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "../containers/Container";
import Logo from "../../assets/image/common/QR-PAY_LOGO.png";
import { IoExitOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const [openSidebar, setOpenSidebar] = useState(false);

  const menus = [
    {
      text: "Home",
      link: "/",
    },
    // {
    //   text: "About us",
    //   link: "#about-us",
    // },
    {
      text: "Contact us",
      link: "#contact-us",
    },
  ];

  return (
    <>
      <header className="box-shadow sticky top-0 hidden md:block z-[999] bg-white">
        <Container className="h-20 flex justify-between items-center">
          <div>
            <img
              src={Logo}
              alt="qr-pay"
              onClick={() => navigate("/")}
              className="w-40 cursor-pointer"
            />
          </div>
          <div className="flex gap-x-6">
            {menus.map((menu, index) => {
              return (
                <a href={menu.link} key={index}>
                  <button
                    className={`${
                      location.pathname === menu.link
                        ? "font-medium text-primary"
                        : "text-grey"
                    } font-pop no-underline hover:underline underline-offset-[10px] transition duration-300`}
                  >
                    {menu.text}
                  </button>
                </a>
              );
            })}
          </div>
        </Container>
      </header>
      <header className="box-shadow sticky top-0 md:hidden z-[999] bg-white">
        <Container className="relative h-20 flex justify-between items-center">
          <img className="mx-auto w-40" src={Logo} alt="qr-pay" />
          <Button
            className="absolute right-6"
            type="ghost"
            icon={<RxHamburgerMenu size="large" />}
            onClick={() => setOpenSidebar(true)}
          />
        </Container>
      </header>
      <div>
        {openSidebar && (
          <div
            className="fixed inset-0 z-[9999]"
            style={{ background: "rgba(0, 0, 0, 0.3)" }}
            onClick={() => setOpenSidebar(false)}
          />
        )}
        <div
          className={`${
            openSidebar ? "w-[260px]" : "w-0"
          } md:hidden fixed inset-y-0 right-0 bg-white z-[99999] transition-all duration-300 overflow-hidden`}
        >
          <div className="flex flex-col items-start gap-4 p-6">
            {menus.map((menu, index) => {
              return (
                <a href={menu.link} key={index}>
                  <Button
                    type="link"
                    onClick={() => {
                      setOpenSidebar(false);
                    }}
                    className={`${
                      location.pathname === menu.link
                        ? "font-medium text-primary"
                        : "text-grey"
                    } font-pop no-underline hover:underline underline-offset-[10px] transition duration-300`}
                  >
                    {menu.text}
                  </Button>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
